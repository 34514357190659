<template>
	<div id="four-oh-four-page" class="page centered-container">
		<img class="svg partner-logo" v-bind:src="imgLogo" :alt="imgAlt" />
		<p class="standard-text">
			<span v-t="'youSeemLost'" />&nbsp;<span v-t="'youProbablyWantToVisit'" />&nbsp;<router-link to="/"><span v-t="'theHomePage'" /></router-link>.
		</p>
	</div>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
	name: 'FourOhFourPage',
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {},
	computed: {
		imgLogo: function() {
			return ar.partnerLogoUrl(this.partner);
		},
		imgAlt: function () {
			return ar.partnerLogoAltText(this.partner);
		}
	},
	mounted: function() {
		window.focusPageHeading(); 
		window.setPageTitle('');
	}
};
</script>

<style scoped>
	#four-oh-four-page {
		text-align: center;
	}

	#four-oh-four-page p {
	}

	#four-oh-four-page a {
		color: white;
		text-decoration: underline;
	}
</style>

<template>
	<div id="asl-consent-page" class="page">
		<template v-if="globalState.menuOpen">
			<div id="blockScreen" class="block-screen" @click="closeMenu"></div>
		</template>
		<Menu></Menu>
		<RemoteHeader :heading="$t('signLanguagePageHeadingLabel')"></RemoteHeader>
		<ASLRemoteHeaderButtonContainer>
			<ASLCloseButton />
		</ASLRemoteHeaderButtonContainer>
		<ASLConsent />
</div>
</template>

<script>
import RemoteHeader from "@/components/RemoteHeader.vue";
import Menu from '@/components/Menu.vue';
import ASLConsent from '@/components/ASLConsent.vue';
import arRouter from '../router';
import ASLRemoteHeaderButtonContainer from "@/components/ASLRemoteHeaderButtonContainer.vue";
import ASLCloseButton from "@/components/ASLCloseButton.vue";
export default {
	data() {
		return {
			globalState: ar.state
		}
	},
	name: "ASLConsentPage",
	components: {
		RemoteHeader,
		Menu,
		ASLConsent,
		ASLRemoteHeaderButtonContainer,
		ASLCloseButton
	},
	methods: {
		checkConsent: function() {
			return ar.getLocal('asl-consent');
		},
		closeMenu: function () {
			if (this.globalState.menuOpen) {
				this.globalState.menuOpen = false;
			}
		}
	},
	beforeMount: function() {
		if( this.checkConsent() === 'true' ) {
			arRouter.push('/asl-interface');
		}
	},
	mounted: function() {
		window.focusPageHeading();
		window.setPageTitle('signLanguagePageHeadingLabel');
	}
};
</script>

<style lang="scss" scoped>

</style>

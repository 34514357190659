<template>
	<div id="ar-seaButtons-page" class="page">
		<template v-if="globalState.menuOpen">
			<div id="blockScreen" class="block-screen" @click="closeMenu"></div>
		</template>
		<Menu></Menu>
		<RemoteHeader :heading="$t('seaButtonsPageHeadingLabel')"></RemoteHeader>
		<div id="seabuttons" class="seabuttons mt-global" :aria-hidden="ariaHidden">
			<div id="seabuttons-buttons" class="" @click.prevent="click">
				<div class="custom-button-section">
					<template v-if="globalState.isFetchingSeaButtons">
						<p class="important">{{ $t('seaButtonsPagePleaseWait') }}...</p>
					</template>
					<template v-else-if="globalState.errFetchingSeaButtons">
						<p class="important">{{ $t('seaButtonsPageErrGettingBtnData') }}</p>
					</template>
					<template v-else>
						<template
							v-if="
								globalState.haveSeaButtonsData &&
								globalState.seaButtonsData &&
								globalState.seaButtonsData.length > 0
							"
						>
							<button
								class="btn"
								v-for="(seaButton, j) in globalState.seaButtonsData"
								:key="j"
								:aria-label="seaButton.displayString"
								:data-command="seaButton.command"
							>
								<img :class="{'soft-img': seaButton.isLegacyIcon}" :alt="seaButton.command" :src="safeImage(seaButton.image)" />
								<label aria-hidden="true" v-html="seaButton.displayString"></label>
							</button>
						</template>
						<template
							v-if="
								globalState.haveSeaButtonsData &&
								globalState.seaButtonsData &&
								globalState.seaButtonsData.length === 0
							"
						>
							<p class="important">
								{{ $t('seaButtonsPageNoButtonsYet') }}
							</p>
						</template>
					</template>
				</div>
				<div class="remote-section" v-show="!isLoading">
					<DPadX1 v-if="!globalState.isFlex"></DPadX1>
					<DPadFlex v-if="globalState.isFlex"></DPadFlex>
					<FullRemote></FullRemote>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DPadFlex from '../components/buttons/DPadFlex.vue';
import DPadX1 from '@/components/buttons/DPadX1.vue';
import FullRemote from '@/components/buttons/FullRemote.vue';
import PleaseWait from '@/components/PleaseWait.vue';
import RemoteHeader from '@/components/RemoteHeader.vue';
import Menu from '@/components/Menu.vue';

const NUM_BUTTONS_PER_ROW_SMALL = 3;
const NUM_BUTTONS_PER_ROW_MED = 4;
const NUM_BUTTONS_PER_ROW_LARGE = 5;
export default {
	name: 'SeaButtonsPage',
	components: {
		DPadFlex,
		DPadX1,
		FullRemote,
		PleaseWait,
		RemoteHeader,
		Menu
	},
	props: [],
	data: function () {
		return {
			isLoading: true,
			globalState: ar.state,
			numButtonsPerRow: 1
		};
	},
	methods: {
		gotoMainPage: function () {
			arRouter.push('/remote');
		},
		gotoEditButtonOrderPage: function () {
			arRouter.push('/my-remote-config');
		},
		closeMenu: function () {
			if (this.globalState.menuOpen) {
				this.globalState.menuOpen = false;
			}
		},
		click: function (event) {
			if (!this.globalState.seaButtonsData) {
				return;
			}

			// WARNING: DOM-Fragile!
			let target, elem, dataset, command, vcode;
			target = event.target;

			if (target.classList.contains('btn')) {
				elem = target;
				if (elem.disabled) {
					// Drop click event
					return;
				}
			} else if (target.parentElement.classList.contains('btn')) {
				elem = target.parentElement;
				if (elem.disabled) {
					// Drop click event
					return;
				}
			} else {
				// Drop click event
				return;
			}
			dataset = elem.dataset;
			command = dataset.command;
			vcode = dataset.vcode;
			if (command) {
				// "Flash" the button to show it was clicked
				elem.classList.add('flash');
				setTimeout(function () {
					elem.classList.remove('flash');
				}, 125);
				// Beep if sound is on
				if (ar.state.keySound) {
					ar.beep();
				}
				// Process the key
				ar.submitTextCommand(command);
			}
			if (vcode) {
				// "Flash" the button to show it was clicked
				elem.classList.add('flash');
				setTimeout(function () {
					elem.classList.remove('flash');
				}, 125);
				// Beep if sound is on
				if (ar.state.keySound) {
					ar.beep();
				}
				// Process the key
				ar.processKey(vcode);
			}
		},
		safeImage: function (imageSrc) {
			return imageSrc || '/images/seabuttons/missingImage.png';
		}
	},
	computed: {
		ariaHidden: function () {
			if (ar.state.menuOpen) {
				return true;
			} else {
				return false;
			}
		},
		imgLogo: function () {
			return ar.partnerLogoUrl(this.globalState.partner);
		},
		imgAlt: function () {
			return ar.partnerLogoAltText(this.partner);
		}
	},
	mounted: function () {
		window.focusPageHeading();
		window.setPageTitle('seaButtonsPageHeadingLabel');
		this.$nextTick(function () {
			const self = this;
			ar.getAccount(function () {
				self.globalState.isFlex = false;
				self.isLoading = false;
			});
		});

		ar.setState({
			customMode: true
		});
		if (ar.isLocalStorageAvailable('localStorage')) {
			ar.saveLocal('customMode', 'true');
		}

		if (!this.globalState.haveSeaButtonsData) {
			ar.getSeaButtonsData();
		}
	}
};
</script>

<style lang="scss" scoped>
.seabutton-button {
	width: 6.25rem;
}

#seabuttons-buttons {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	@include lg {
		align-items: start;
		flex-direction: row;
	}
}

.seabuttons {
	margin-left: 3rem;
	margin-right: 3rem;
}

.seabtn-wrapper {
	width: 6.5rem;

	p {
		font-family: 'XfinityStandard-Medium';
		color: white;
		font-size: 1rem;
		margin-top: 0.25rem;
	}
}

.remote-section {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-bottom: 2rem;

	.button-section {
		margin-right: 0;

		.dpad-group {
			margin-bottom: 0;
		}
	}
}
.custom-button-section {
	display: grid;
	gap: 0.5rem;
	grid-template-columns: auto auto auto;
	margin-bottom: 2rem;

	@include lg {
		grid-template-columns: auto auto auto auto;
		padding: 0.5rem;
		margin-top: -0.5rem;
		margin-right: 3.5rem;
		max-height: 40rem;
		overflow-y: auto;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			width: 0.5rem;
		}

		&::-webkit-scrollbar-track {
			background-color: rgb(162, 155, 155);
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgb(224, 217, 217);
			border-radius: 10px;
		}
	}

	@include xl {
		grid-template-columns: auto auto auto auto auto auto;
	}
}
</style>

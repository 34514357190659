<!-- a copy of views > AddNewButtonPage.vue -->
<template>
	<div id="asl-interface-page" class="page">
		<template v-if="globalState.menuOpen">
			<div id="blockScreen" class="block-screen" @click="closeMenu"></div>
		</template>
		<Menu></Menu>
		<RemoteHeader :heading="$t('signLanguagePageHeadingLabel')"></RemoteHeader>
		<ASLRemoteHeaderButtonContainer>
			<ASLGettingStartedHelpLink />
			<ASLCloseButton />
		</ASLRemoteHeaderButtonContainer>
		<ASLInterface />
	</div>
</template>

<script>
import RemoteHeader from "@/components/RemoteHeader.vue";
import Menu from '@/components/Menu.vue';
import ASLInterface from "@/components/ASLInterface.vue";
import aslInterfaceMixin from '@/components/mixins/aslInterface';
import ASLRemoteHeaderButtonContainer from "@/components/ASLRemoteHeaderButtonContainer.vue";
import ASLGettingStartedHelpLink from '@/components/ASLGettingStartedHelpLink.vue'
import ASLCloseButton from "@/components/ASLCloseButton.vue";
export default {
	data() {
		return {
			globalState: ar.state
		}
	},
	name: "ASLInterfacePage",
	mixins: [aslInterfaceMixin],
	components: {
		RemoteHeader,
		Menu,
		ASLInterface,
		ASLRemoteHeaderButtonContainer,
		ASLCloseButton,
		ASLGettingStartedHelpLink
	},	
	methods: {
		closeMenu: function () {
			if (this.globalState.menuOpen) {
				this.globalState.menuOpen = false;
			}
		}
	},
	beforeMount: function() {
		if( ar.getLocal('asl-consent') !== 'true' ) {
			arRouter.push('/asl-consent');
		}
		else if( ar.getLocal('viewed-asl-getting-started') !== 'true' ) {
			arRouter.push('/asl-getting-started');
		}
	},
	mounted: function() {
		window.focusPageHeading();
		window.setPageTitle('signLanguagePageHeadingLabel');
	}
};
</script>

<style lang="scss" scoped>
	#asl-interface-page {
		display: flex;
		flex-direction: column;
		background-color: $ar-background-color;
		height: 100vh;
	}
</style>

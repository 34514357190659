import { render, staticRenderFns } from "./ASLGettingStartedPage.vue?vue&type=template&id=811fb0a0&scoped=true"
import script from "./ASLGettingStartedPage.vue?vue&type=script&lang=js"
export * from "./ASLGettingStartedPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../deps/0/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "811fb0a0",
  null
  
)

export default component.exports
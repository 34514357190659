<template>
	<div id="waiting-for-tv" class="">
		<img class="svg partner-logo" v-bind:src="imgLogo" :alt="imgAlt" />

		<p id="waiting-for-tv-heading" class="standard-heading" role="heading" aria-level="1" v-t="'continueSetupWithEasyPair'" />

		<p id="waiting-for-tv-text" class="standard-text" v-t="'useRemoteThenEnterCode'" />

		<p id="pairing-code" class="standard-text">{{pairingCode}}</p>
	</div>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
	name: 'WaitingForTv',
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {
		hideMsg: function(event) {
			ar.clearMessage();
		}
	},
	computed: {
		imgLogo: function() {
			return ar.partnerLogoUrl(this.partner);
		},
		imgAlt: function () {
			return ar.partnerLogoAltText(this.partner);
		},
	}
};
</script>

<style scoped>
</style>

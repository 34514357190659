<template>
    <div class="asl-remote-header-buttons" :class="{ ['menu-open']: globalState.menuOpen === true }">
        <slot></slot>
    </div>
</template>

<script>
export default {
    data() {
		return {
			globalState: ar.state
		}
	},
    name: 'ASLRemoteHeaderButtonContainer',
};
</script>

<style lang="scss" scoped>
.asl-remote-header-buttons {
    display: flex;
    gap: 0.5rem;
    position: absolute;
    top: 0.5rem;
    right: 0.25rem;
    z-index: 1000;
    height: 2rem;

    &.menu-open {
        /* If the hamburger menu is open, reduce the z-index so that the buttons show below the menu overlay. */
        z-index: 49;
    }
    

    & :focus {
        @include focus-outline;
    }
}

@include tp {
    .asl-remote-header-buttons {
        top: 1rem;
        right: 1rem;
    }
}

@include md {
    .asl-remote-header-buttons {
        top: 1rem;
        right: 1.5rem;
        height: 3.25rem;
    }
}
</style>

<template>
	<div id="error" class="page centered-container">
		<img class="svg partner-logo" v-bind:src="imgLogo" :alt="imgAlt" />

		<p role="heading" aria-level="1" id="error-heading" class="standard-heading" v-t="'unrecoverableErrorOccurred'" />

		<p id="error-text" class="standard-text">{{displayMessage}}</p>

		<template v-if="arToken">
			<a class="standard-button" href="#" v-on:click.prevent="back"><span v-t="'goBack'" /></a>
		</template>
		<template v-else>
			<a class="standard-button" href="#" v-on:click.prevent="home"><span v-t="'goHome'" /></a>
		</template>
	</div>
</template>

<script>
import i18nJ from '@/plugins/i18nJ';

export default {
	name: 'ErrorPage',
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {
		home: function () {
			arRouter.push('/');
		},
		back: function () {
			ar.goToCorrectRemotePage();
		}
	},
	computed: {
		imgLogo: function() {
			return ar.partnerLogoUrl(this.partner);
		},
		imgAlt: function () {
			return ar.partnerLogoAltText(this.partner);
		},
		displayMessage: function() {
			const urlParams = new URLSearchParams(window.location.search);
			const err = urlParams.get('err');
			const sts = urlParams.get('sts');
			let   msg = urlParams.get('msg');
			let theMessage;
			if ( err === 'ERROR' && sts ) {
				msg = i18nJ.t('wereSorryErrorOccurred');
				theMessage = msg + ' (' + sts + ')';
			} else if ( err === 'INVALID-TOKEN' ) {
				theMessage = i18nJ.t('needToRelogin');
			} else if ( err === 'ERROR' ) {
				theMessage = i18nJ.t('wereSorryInternalError');
			} else if ( err ) {
				msg = i18nJ.t('wereSorryUnexpectedError');
				theMessage = msg + ' (' + err + ')';
			} else if ( msg ) {
				theMessage = msg;
			} else {
				theMessage = i18nJ.t('wereSorrySomethingWeird');
			}
			return theMessage;
		}
	}, 
	mounted: function() {
		window.focusPageHeading();
		window.setPageTitle('unrecoverableErrorOccurred');
	}
};
</script>

<style scoped>
	#error-text {
		margin-bottom: 3rem;
	}
</style>

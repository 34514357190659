<template>
	<div id="settings-container">

		<div id="settings-content">

			<img class="svg partner-logo" v-bind:src="imgLogo" :alt="imgAlt" />

			<h1>{{ $t('settingsPagePrivacySettings') }}</h1>

			<p id="privacy-explanation">
				{{ $t('settingsPagePrivacyMessage') }}
			</p>

			<div id="buttons">
				<button class="standard-button primary"
						@click="handleAcceptAllClick">
					{{ $t('settingsPageAcceptAll') }}
				</button>
				<button class="standard-button primary"
						@click="handleAcceptSelectionClick">
					{{ $t('settingsPageAcceptSelection') }}
				</button>
				<button class="standard-button primary"
						@click="handleRejectAllClick">
					{{ $t('settingsPageRejectAll') }}
				</button>
			</div>

			<div id="checkboxes" v-if="shouldShowCheckboxes">
				<input type="checkbox" id="necessary" name="necessary"
					   checked="true" disabled="disabled">
				<label for="necessary">{{ $t('settingsPageNecessary') }}</label>

				<input type="checkbox" id="analytics" name="analytics"
					   v-model="consent.analytics">
				<label for="analytics">{{ $t('settingsPageAnalytics') }}</label>
			</div>

			<div id="finish-buttons"  v-if="shouldShowFinishButtons">
				<button class="standard-button primary" @click="handleSaveButtonClick">
					{{ $t('settingsPageSave') }}
				</button>
				<!--
				<button class="standard-button cancel secondary" @click="handleCancelButtonClick">
					{{ $t('settingsPageCancel') }}
				</button>
				-->
			</div>

		</div>

	</div>
</template>

<script>
import { Analytics } from '../analytics.js';

export default {
	name: 'Settings',
	data: function() {
		return {
			overallConsent: undefined,
			consent: {
				necessary: true,
				analytics: false
			}
		};
	},
	methods: {
		setConsentMode: function(consent) {
			const consentMode = {
				functionality_storage : consent.necessary   ? 'granted' : 'denied',
				security_storage      : consent.necessary   ? 'granted' : 'denied',
				ad_storage            : consent.marketing   ? 'granted' : 'denied',
				analytics_storage     : consent.analytics   ? 'granted' : 'denied',
				personalization       : consent.preferences ? 'granted' : 'denied'
			};
			gtag('consent', 'update', consent);
			localStorage.setItem('consentMode', JSON.stringify(consentMode));
		},

		handleAcceptAllClick: function() {
			Analytics.track({ eventName: 'privacy_accept_all', properties: undefined });
			this.overallConsent = 'ACCEPT_ALL';
			// Any/all consent keys other than required, which is always true
			this.consent.analytics = true;
			this.handleSaveButtonClick();
		},

		handleAcceptSelectionClick: function() {
			Analytics.track({
				eventName: 'privacy_accept_some',
				properties: {
					analytics: this.consent.analytics
				}
			});
			this.overallConsent = 'ACCEPT_SELECTION';
		},

		handleRejectAllClick: function() {
			Analytics.track({ eventName: 'privacy_reject_all', properties: undefined });
			this.overallConsent = 'REJECT_ALL';
			// Any/all consent keys other than required, which is always true
			this.consent.analytics = false;
			this.handleSaveButtonClick();
		},

		handleSaveButtonClick: function() {
			Analytics.track({ eventName: 'privacy_save', properties: undefined });
			localStorage.setItem('consentAppData', JSON.stringify(this.consent));
			this.setConsentMode(this.consent);
			// .go(-1) would be better but if the user comes here first thing,
			// there's no previous page to go to. The remote page will redirect
			// if the user isn't authenticated
			arRouter.push('/remote');
		},

		handleCancelButtonClick: function() {
			Analytics.track({ eventName: 'privacy_cancel', properties: undefined });
			// .go(-1) would be better but if the user comes here first thing,
			// there's no previous page to go to. The remote page will redirect
			// if the user isn't authenticated
			arRouter.push('/remote');
		}
	},
	computed: {
		imgLogo: function() {
			return ar.partnerLogoUrl(ar.state.partner);
		},
		imgAlt: function () {
			return ar.partnerLogoAltText(ar.state.partner);
		},

		shouldShowCheckboxes: function() {
			return ( this.overallConsent === 'ACCEPT_SELECTION' );
		},

		shouldShowFinishButtons: function() {
			return this.shouldShowCheckboxes;
		}
	},
	mounted: function() {
		this.consent.necessary = true;

		const consentAppData = localStorage.getItem('consentAppData');
		if ( consentAppData === null ) {
			this.consent.analytics = false;
			localStorage.setItem('consentAppData', JSON.stringify(this.consent));
		} else {
			try {
				const oConsentAppData = JSON.parse(consentAppData);
				// Set component's model state based on saved consentAppData
				// All keys other than 'necessary' which is always true (above)
				if ( oConsentAppData.hasOwnProperty('analytics') ) {
					this.consent.analytics = oConsentAppData.analytics;
				} else {
					// In case somehow the data stored is weird, default to false
					this.consent.analytics = false;
				}
			} catch ( ex ) {
				// Could not parse consentAppData from localStorage
				this.consent.analytics = false;
			}
		}
	}
};
</script>

<style scoped>
#settings-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	
}

#settings-content {
	width: 90%;
	height: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 50vh;
}

.partner-logo {
	margin-bottom: 0.4rem;
}

h1 {
	margin-top: 0;
	opacity: 0.95;
	font-size: 2rem;
}

#privacy-explanation {
	opacity: 0.8;
	width: 80%;
	font-size: 1.25rem;
	margin-bottom: 1rem;
}

#buttons {
	margin-bottom: 0.7rem;
}

button {
	margin-left: 1rem;
	margin-right: 1rem;
}

@media screen and (max-width: 650px) {
	#buttons {
		display: flex;
		flex-direction: column;
	}

	button {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 1rem;
	}
}

#checkboxes {
	margin-top: 1.3rem;
}

#checkboxes input[type=checkbox] {
	border: 0px;
	width: 1.5rem;
	height: 1.5rem;
	margin-left: 1.5rem;
}

#checkboxes label {
	font-size: 1.5rem;
	margin-right: 1.5rem;
}

#finish-buttons {
	margin-top: 1rem;
}
</style>
